<template>
  <div>
    <HomePageTop/>
    <!-- <div class="top"></div> -->
    <!--普检精检详细说明 -->
    <div class="head" style="margin-top: -32px">
      <div class="base-width">
        <!--        <h2>普检精检详细说明</h2>-->
        <!--        <p>我们承诺将向客户提供高质量、准确的商品检品服务。</p>-->
        <!--        <p>我们的特点是根据不同等级的会员客户提供不同的服务。</p>-->
        <h2>検品について</h2>
        <p>私たちはお客様に高品質で正確な商品検品サービスを提供することをお約束します。</p>
        <p>お客様の需要に応じ、付属サービスを提供させていただきます。</p>
      </div>
    </div>
    <!-- 文字介绍 -->
    <div class="text">
      <!--      我们会对是对各类商品进行检品，以验证其品质和真实性。 <br>-->
      <!--      在整个过程中，我们采用最先进的技术和设备,最专业的人员，并遵循严格的检品标准，以确保准确度和可靠性。 <br>-->
      <!--      除了标准检品之外，我们还提供定制服务，满足不同客户的特殊需求。 <br>-->
      <!--      我们可以根据客户的要求进行样品检测、分类和均匀混合等操作，并按照客户的要求编写报告。-->
      各種商品の品質と内容を確認するため、私たちは検品を行います。<br>
      このプロセス全体で、最先端の技術と設備、最も専門的なスタッフでこなし、厳格な検品基準に従って正確性と信頼性を確保しています。<br>
      標準の検品に加えて、特殊な要求を持つさまざまなお客様のニーズに応えるカスタマイズサービスも提供しています。<br>
      お客様の要求に応じてサンプルのテスト、分類、均一混合などの操作を行い、報告書の作成も対応可能。
    </div>
    <!-- 收费说明 -->
    <div class="collectFee">
      <div class="base-width">
        <h3>
          <!--          收费说明-->
          費用について
        </h3>
        <div class="vip">
          <div class="item">
            <div class="grade">{{ $fanyi("普通会员") }}</div>
          </div>
          <div class="item">
            <div class="grade">{{ $fanyi("定额会员") }}</div>
          </div>
          <div class="item">
            <div class="grade">SVIP</div>
          </div>
        </div>
        <div class="row">
          <div class="label">簡易検品</div>
          <div class="item">無料</div>
          <div class="item">無料</div>
          <div class="item">無料</div>
        </div>
        <div class="row">
          <div class="label">詳細検品</div>
          <div class="item">商品代6%</div>
          <div class="item">商品代5%</div>
          <div class="item">商品代4%</div>
        </div>
        <!--        <div class="row">-->
        <!--          <div class="label">詳細検品（表＋裏）</div>-->
        <!--          <div class="item">なし</div>-->
        <!--          <div class="item">商品代8%</div>-->
        <!--          <div class="item">商品代6%</div>-->
        <!--        </div>-->
      </div>
    </div>
    <!-- 服务说明 -->
    <div class="serve">
      <div class="base-width">
        <h3>
          検品説明
        </h3>
        <div class="colorB4272B" style="margin-bottom:20px;text-align: right">
          ※ 最終更新日: 2024.1.23
        </div>
        <div class="vip">
          <div class="item">
            <div class="grade">{{ $fanyi("普通会员") }}</div>
          </div>
          <div class="item">
            <div class="grade">{{ $fanyi("定额会员") }}</div>
          </div>
          <div class="item">
            <div class="grade">SVIP</div>
          </div>
        </div>
        <!-- 簡易検品 -->
        <div class="sizedmaterial">
          <div class="label">
            <h6>簡易検品</h6>
            <!--                        <div class="video"></div>-->
            <img src="../../assets/zunb.png" alt="">
          </div>
          <div class="item">
            <div class="title">開封検品【全ての商品を開封検品】</div>
            <p>1）外観が注文商品ページと大きな乖離が無いかどうか。</p>
            <p>
              ※開封するとシール等の開封跡が付く可能性のある商品は、事前にお客様に確認します。開封不可の場合は、検品は免責とさせて頂きます。</p>
            <p>2）数量の確認</p>
            <p>【金属極小部品やアクセサリー製品、手工芸品は重量での確認になる為、数％の誤差あり】</p>
            <p>
              3）サイズ（表記）や色が注文と一致しているか<br>
              【注文サイズとサイズラベルを確認、サイズ測定は行わず、若干の色差に於いては太陽光やディスプレイの誤差の為、免責とさせて頂きます。】
            </p>
            <p>検査免除商品：</p>
            <p>ストッキング：引っ掛ける可能性があるため、検品できません。<br>ウィッグ：基本的には品質の問題はなく、スタイルの照合のみ。<br>※（類似カラーの認識、仕分け出来ません。カラー名や品番で識別）<br>衣料品の金属小部品・アクセサリー等：<br>商品が小さく多いため、品質を確認することができません。（アクセサリー：イヤリング、イヤーカフ、ネックレス、ヘアクリップなど）
            </p>
          </div>
          <div class="item">
            <div class="title">開封検品【全ての商品を開封検品】</div>
            <p>1）外観が注文商品ページと大きな乖離が無いか。</p>
            <p>
              ※開封するとシール等の開封跡が付く可能性のある商品は、事前にお客様に確認します。開封不可の場合は、検品は免責とさせて頂きます。</p>
            <p>2）数量の確認</p>
            <p>【金属極小部品やアクセサリー製品、手工芸品は重量での確認になる為、数％の誤差あり】</p>
            <p>
              3）サイズ（表記）や色が注文と一致しているか <br>
              【注文サイズとサイズラベルを確認、サイズ測定は行わず、若干の色差に於いては太陽光やディスプレイの誤差の為、免責とさせて頂きます。】
            </p>
            <p style="color:#B4272B">4）抜き取り検査でサイズを測定 <br>【各サイズの商品を1つ測定して、サイズの比較を並べて行います。】
            </p>
            <p>検査免除商品：</p>
            <p>ストッキング：引っ掛ける可能性があるため、検品できません。<br>ウィッグ：基本的には品質の問題はなく、スタイルの照合のみ。<br>※（類似カラーの認識、仕分け出来ません。カラー名や品番で識別）<br>衣料品の金属小部品・アクセサリー等：<br>商品が小さく多いため、品質を確認することができません。（アクセサリー：イヤリング、イヤーカフ、ネックレス、ヘアクリップなど）
            </p>
          </div>
          <div class="item">
            <div class="title">開封検品【全ての商品を開封検品】</div>
            <p>1）外観が注文商品ページと大きな乖離が無いか。</p>
            <p>
              ※開封するとシール等の開封跡が付く可能性のある商品は、事前にお客様に確認します。開封不可の場合は、検品は免責とさせて頂きます。</p>
            <p>2）数量の確認</p>
            <p>【金属極小部品やアクセサリー製品、手工芸品は重量での確認になる為、数％の誤差あり】</p>
            <p>
              3）サイズ（表記）や色が注文と一致しているか <br>
              【注文サイズとサイズラベルを確認、サイズ測定は行わず、若干の色差に於いては太陽光やディスプレイの誤差の為、免責とさせて頂きます。】
            </p>
            <p style="color:#B4272B">4）抜き取り検査でサイズを測定 <br>【各サイズの商品を1つ測定して、サイズの比較を並べて行います。】
            </p>
            <p>検査免除商品：</p>
            <p>ストッキング：引っ掛ける可能性があるため、検品できません。<br>ウィッグ：基本的には品質の問題はなく、スタイルの照合のみ。<br>※（類似カラーの認識、仕分け出来ません。カラー名や品番で識別）<br>衣料品の金属小部品・アクセサリー等：<br>商品が小さく多いため、品質を確認することができません。（アクセサリー：イヤリング、イヤーカフ、ネックレス、ヘアクリップなど）
            </p>
          </div>
        </div>
        <!-- img -->
        <!--        <div class="imgs">-->
        <!--          <div class="item" v-for="item in 5" :key="item">-->
        <!--          </div>-->
        <!--        </div>-->
        <!-- 詳細検品（表） -->
        <div class="sizedmaterial">
          <div class="label">
            <h6>詳細検品</h6>
            <!--            <div class="video"></div>-->
            <img src="../../assets/zunb.png" alt="">
          </div>
          <div class="item">
            <p>1）一般商品：</p>
            <p>外観検査：破損、汚れ、傷などの有無、生産ロットの一致（発注数量内の生地のバラつきや色差等）、内部の形状の確認<br>
              韓国語のタグ（輸入不可の為）、①乾燥剤等（付属品）、②合格証、③中敷紙の除去<br>
              ※①②③事前に指示があれば対応可<br>
              適切な大きさのOPP袋に交換し、包装を整えます。<br>
              ※割れやすい商品（ガラス、陶磁器、樹脂、不規則な形状の商品）は、緩衝材で包装可能（個別梱包の場合は別途オプション料金必要）
            </p>
            <p>2）衣類：一般的な商品の外観検査に加えて<br>
              サイズラベルの照合：内外の一致、セットの一致、縫い付けられたサイズラベルと外装表記サイズの一致、実際のサイズとウェブ上サイズの一致（現物比較）<br>
              ファスナーのスムーズさの確認<br>
              ボタンホール：デフォルトで開ける ポケットについては別途問合せ→指示が必要です。<br>
            </p>
            <p>3）バッグ：一般検査に以下の検査を追加します。</p>
            <p> 金属パーツ：ボタンの外観の欠陥、外れ、ファスナーの動作を確認します。<br> アクセサリー：ホックなどの破損を確認します。
            </p>
            <p>4）パッケージング：シューズ箱は通常廃棄、OPP袋に変更、シューズサイズラベルを貼付します。（※国際送料を下げる為であり、必要な際は事前に注文書備考欄又はチャット担当者へ連絡）<br>
              商品の確認：左右の足のサイズ、靴底のサイズがウェブ上の表示と一致するか確認します
            </p>
            <!--            <p style="color:#B4272B">实际尺寸与网页一致</p>-->
            <p>5）検査免除商品：</p>
            <p> ストッキング：引っ掛ける可能性があるため、検品できません。<br>ウィッグ：基本的には品質の問題はなく、スタイルの照合のみ。<br>
              ※（類似カラーの認識、仕分け出来ません。カラー名や品番で識別）<br>
              衣料品の金属小部品・アクセサリー等：商品が小さく多いため、品質を確認することができません。（アクセサリー：イヤリング、イヤーカフ、ネックレス、ヘアクリップなど）
            </p>
          </div>
          <div class="item">
            <p>1）一般商品：</p>
            <p>外観検査：破損、汚れ、傷などの有無、生産ロットの一致（発注数量内の生地のバラつきや色差等）、内部の形状の確認<br>
              韓国語の吊り札（輸入不可の為）、①乾燥剤等（付属品）、②合格証、③中敷紙の除去<br>
              ※①②③事前に指示があれば対応可<br>
              適切な大きさのOPP袋に交換し、包装を整えます。<br>
              ※割れやすい商品（ガラス、陶磁器、樹脂、不規則な形状の商品）は、緩衝材で包装可能（個別梱包の場合は別途オプション料金必要）
            </p>
            <p>2）衣類：簡易検品に以下の検査を追加：<br> ①折り畳まれた状態から、拡げて細部まで確認。<br>
              ②サイズラベルの確認：縫い付けられたサイズラベルと外装表記サイズの一致、実際のサイズとウェブ上の表示の一致（現物比較）を確認します。<br>
              金属パーツ：ボタンの外観の欠陥、取り落としの確認、ファスナーの動作を確認します。<br>
              アクセサリー：ホックなどの破損を確認します。<br>
              ボタンホール：デフォルトで開ける ポケットについては別途問合せ→指示が必要です。
            </p>
            <p>3）バッグ：一般検査に以下の検査を追加します。</p>
            <p> 金属パーツ：ボタンの外観の欠陥、外れ、ファスナーの動作を確認します。<br> アクセサリー：ホックなどの破損を確認します。
            </p>
            <p>4）パッケージング：シューズ箱は通常廃棄、OPP袋に変更、シューズサイズラベルを貼付します。（※国際送料を下げる為であり、必要な際は事前に注文書備考欄又はチャット担当者へ連絡）<br>
              商品の確認：左右の足のサイズ、靴底のサイズがウェブ上の表示と一致するか確認します
            </p>
            <!--            <p style="color:#B4272B">实际尺寸与网页一致</p>-->
            <p>5）詳細検品出来ない物：<span
                style="color:#B4272B">【専門的な商品や、特殊な検品が必要な場合は業務に連絡して検品内容を相談して下さい、別途お見積りとなります。】</span>
            </p>
            <p> ストッキング：引っかけやすいため、検査できません。<br>ウィッグ：基本的には品質の問題はなく、スタイルの照合のみ（類似カラーの認識、仕分け出来ません。カラー名や品番で識別）<br>
              服の金属小部品：商品が小さいため品質を確認する事は出来ません。<br>
              アクセサリー：イヤリング、イヤクリップ、ネックレス、ヘアクリップなど</p>
            <p style="color:#B4272B">6）その他：</p>
            <p style="color:#B4272B;margin-top: 20px;">
              特別な検品要求がある場合は担当者に連絡し、個別検討（見積り）させて頂きます。</p>
          </div>
          <div class="item">
            <p>1）一般商品：</p>
            <p>外観検査：破損、汚れ、傷などの有無、生産ロットの一致（発注数量内の生地のバラつきや色差等）、内部の形状の確認<br>
              韓国語の吊り札（輸入不可の為）、①乾燥剤等（付属品）、②合格証、③中敷紙の除去<br>
              ※①②③事前に指示があれば対応可<br>
              適切な大きさのOPP袋に交換し、包装を整えます。<br>
              ※割れやすい商品（ガラス、陶磁器、樹脂、不規則な形状の商品）は、緩衝材で包装可能（個別梱包の場合は別途オプション料金必要）
            </p>
            <p>2）衣類：簡易検品に以下の検査を追加：<br> ①折り畳まれた状態から、拡げて細部まで確認。<br>
              ②サイズラベルの確認：縫い付けられたサイズラベルと外装表記サイズの一致、実際のサイズとウェブ上の表示の一致（現物比較）を確認します。<br>
              金属パーツ：ボタンの外観の欠陥、取り落としの確認、ファスナーの動作を確認します。<br>
              アクセサリー：ホックなどの破損を確認します。<br>
              ボタンホール：デフォルトで開ける ポケットについては別途問合せ→指示が必要です。
            </p>
            <p>3）バッグ：一般検査に以下の検査を追加します。</p>
            <p> 金属パーツ：ボタンの外観の欠陥、外れ、ファスナーの動作を確認します。<br> アクセサリー：ホックなどの破損を確認します。
            </p>
            <p>4）パッケージング：シューズ箱は通常廃棄、OPP袋に変更、シューズサイズラベルを貼付します。（※国際送料を下げる為であり、必要な際は事前に注文書備考欄又はチャット担当者へ連絡）<br>
              商品の確認：左右の足のサイズ、靴底のサイズがウェブ上の表示と一致するか確認します
            </p>
            <!--            <p style="color:#B4272B">实际尺寸与网页一致</p>-->
            <p>5）詳細検品出来ない物：<span
                style="color:#B4272B">【専門的な商品や、特殊な検品が必要な場合は業務に連絡して検品内容を相談して下さい、別途お見積りとなります。】</span>
            </p>
            <p> ストッキング：引っかけやすいため、検査できません。<br> ウィッグ：基本的には品質の問題はなく、スタイルの照合のみ
              （類似カラーの認識、仕分け出来ません。カラー名や品番で識別）<br>
              服の金属小部品：商品が小さいため品質を確認する事は出来ません。<br>
              アクセサリー：イヤリング、イヤクリップ、ネックレス、ヘアクリップなど</p>
            <p style="color:#B4272B">6）その他：</p>
            <p style="color:#B4272B;margin-top: 20px;">
              特別な検品要求がある場合は担当者に連絡し、個別検討（見積り）させて頂きます。</p>
          </div>
        </div>
        <!-- img -->
        <!--        <div class="imgs">-->
        <!--          <div class="item" v-for="item in 5" :key="item">-->
        <!--          </div>-->
        <!--        </div>-->
        <!-- 詳細検品（表＋裏） -->
        <!--        <div class="sizedmaterial">-->
        <!--          <div class="label">-->
        <!--            <h6>詳細検品（表＋裏）</h6>-->
        <!--            &lt;!&ndash;            <div class="video"></div>&ndash;&gt;-->
        <!--            <img src="../../assets/zunb.png" alt="">-->
        <!--          </div>-->
        <!--          <div class="item">-->
        <!--            <p>なし</p>-->
        <!--          </div>-->
        <!--          <div class="item">-->
        <!--            <p>1）一般商品：</p>-->
        <!--            <p> 外観検査：破損、汚れ、傷などの有無、生産ロットの一致、<span style="color:#B4272B">内部の形状の確認</span></p>-->

        <!--            <p>韓国語のタグ、乾燥剤、合格証、中質紙の除去</p>-->
        <!--            <p style="color:#B4272B">割れ物【ガラス、陶磁器、樹脂、不規則な形状の商品】は、緩衝材で包装</p>-->
        <!--            <p>-->
        <!--              2）衣類：一般検査に以下の検査を追加<br> サイズラベルの確認：内外の一致、セットの一致、縫い目のサイズと外装の一致、実際のサイズとウェブ上の表示の一致を確認します<br>-->
        <!--              金属パーツ：ボタンの外観の欠陥、取り落としの確認、ファスナーの動作を確認します<br> アクセサリー：ポケット、ホックなどの破損を確認します<br>-->
        <!--              ボタンホール：デフォルトでは開けます。ただし、ジーンズや皮製品などは針穴を残す場合は別途連絡が必要です-->
        <!--            </p>-->
        <!--            <p>手順：<span style="color:#B4272B">すべての糸の始末を行います【糸の始末範囲は1cm以上を確認します】。内部も検品します</span></p>-->
        <!--            <p>3）バッグ：一般検査に以下の検査を追加 <br>金属パーツ：ボタンの外観の欠陥、取り落としの確認、ファスナーの動作を確認。-->
        <!--              アクセサリー：すべてのポケット、ホックなどの破損を確認します </p>-->
        <!--            <p>4）配饰：<span style="color:#B4272B">所有口袋</span>，子母扣等是否破损 靴：一般検査に以下の検査を追加します<br>-->
        <!--              包装：デフォルトでは靴箱は不要で、OPP袋に交換します。左右の足のサイズ、靴底、インソール、舌のサイズを確認し、実際のサイズとウェブ上の表示の一致を確認します<br>-->
        <!--              5）免検商品：【デフォルトでは免検ですが、特別な検査が必要な場合は、担当者に連絡して確認して】<br>-->
        <!--              ストッキング：引っかけやすいため、検査できません<br> ウィッグ：基本的には品質の問題はなく、スタイルの確認のみ<br> 服の金属小部品：商品が小さいため品質を確認することはできません<br>-->
        <!--              アクセサリー：イヤリング、イヤクリップ、ネックレス、ヘアクリップなど <br>-->
        <!--              6）其他：有特殊检品 要求，联系业务-->
        <!--              确认讨论"</p>-->
        <!--          </div>-->
        <!--          <div class="item">-->
        <!--            <p>1）一般商品：</p>-->
        <!--            <p> 外観検査：破損、汚れ、傷などの有無、生産ロットの一致、<span style="color:#B4272B">内部の形状の確認</span></p>-->

        <!--            <p>韓国語のタグ、乾燥剤、合格証、中質紙の除去</p>-->
        <!--            <p style="color:#B4272B">割れ物【ガラス、陶磁器、樹脂、不規則な形状の商品】は、緩衝材で包装</p>-->
        <!--            <p>-->
        <!--              2）衣類：一般検査に以下の検査を追加<br> サイズラベルの確認：内外の一致、セットの一致、縫い目のサイズと外装の一致、実際のサイズとウェブ上の表示の一致を確認します<br>-->
        <!--              金属パーツ：ボタンの外観の欠陥、取り落としの確認、ファスナーの動作を確認します<br> アクセサリー：ポケット、ホックなどの破損を確認します<br>-->
        <!--              ボタンホール：デフォルトでは開けます。ただし、ジーンズや皮製品などは針穴を残す場合は別途連絡が必要です-->
        <!--            </p>-->
        <!--            <p>手順：<span style="color:#B4272B">すべての糸の始末を行います【糸の始末範囲は1cm以上を確認します】。内部も検品します</span></p>-->
        <!--            <p>3）バッグ：一般検査に以下の検査を追加 <br>金属パーツ：ボタンの外観の欠陥、取り落としの確認、ファスナーの動作を確認。-->
        <!--              アクセサリー：すべてのポケット、ホックなどの破損を確認します </p>-->
        <!--            <p>4）配饰：<span style="color:#B4272B">所有口袋</span>，子母扣等是否破损 靴：一般検査に以下の検査を追加します<br>-->
        <!--              包装：デフォルトでは靴箱は不要で、OPP袋に交換します。左右の足のサイズ、靴底、インソール、舌のサイズを確認し、実際のサイズとウェブ上の表示の一致を確認します<br>-->
        <!--              5）免検商品：【デフォルトでは免検ですが、特別な検査が必要な場合は、担当者に連絡して確認して】<br>-->
        <!--              ストッキング：引っかけやすいため、検査できません<br> ウィッグ：基本的には品質の問題はなく、スタイルの確認のみ<br> 服の金属小部品：商品が小さいため品質を確認することはできません<br>-->
        <!--              アクセサリー：イヤリング、イヤクリップ、ネックレス、ヘアクリップなど <br>-->
        <!--              6）其他：有特殊检品 要求，联系业务-->
        <!--              确认讨论"</p>-->
        <!--          </div>-->
        <!--          &lt;!&ndash;          <div class="item">&ndash;&gt;-->
        <!--          &lt;!&ndash;            <p>普通会员暂不提供</p>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </div>&ndash;&gt;-->

        <!--        </div>-->
        <!-- img -->
        <!--        <div class="imgs">-->
        <!--          <div class="item" v-for="item in 5" :key="item">-->
        <!--          </div>-->
        <!--        </div>-->
      </div>

    </div>
    <!--    <div class="foot">-->
    <!--      &lt;!&ndash;      <div class="base-width">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <h5>我们非常注重客户服务。因此，针对不同等级的会员客户提供不同的服务是我们的一个关键特点。</h5>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <div class="isVip">&ndash;&gt;-->
    <!--      &lt;!&ndash;          <div class="label">针对普通会员</div>&ndash;&gt;-->
    <!--      &lt;!&ndash;          <div class="value">提供现场检查服务，以便让客户及时了解其所购买的商品是否符合质量标准。&ndash;&gt;-->
    <!--      &lt;!&ndash;            提供在线支持，回答客户的疑问并提供帮助。&ndash;&gt;-->
    <!--      &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <div class="isVip">&ndash;&gt;-->

    <!--      &lt;!&ndash;          <div class="value">提供客户经理一站式服务，以满足其更加广泛的需求和问题。&ndash;&gt;-->
    <!--      &lt;!&ndash;            可以针对客户的需求进行定制化、综合性的检查工作。&ndash;&gt;-->
    <!--      &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;          <div class="label">针对高级会员</div>&ndash;&gt;-->
    <!--      &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--      <div class="base-width">-->
    <!--        <h5>弊社は日本人の管理体制を</h5>-->
    <!--        <div class="isVip">-->
    <!--          <div class="label">一般会員</div>-->
    <!--          <div class="value">提供现场检查服务，以便让客户及时了解其所购买的商品是否符合质量标准。-->
    <!--            提供在线支持，回答客户的疑问并提供帮助。-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="isVip">-->

    <!--          <div class="value">提供客户经理一站式服务，以满足其更加广泛的需求和问题。-->
    <!--            可以针对客户的需求进行定制化、综合性的检查工作。-->
    <!--          </div>-->
    <!--          <div class="label">定額会員・SVIP会員</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <FootVue/>
  </div>
</template>
<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import FootVue from "../../components/foot/Foot.vue";

export default {
  components: {
    HomePageTop,
    FootVue
  },
  data() {
    return {};
  },
  methods: {}
}
</script>
<style lang='scss' scoped>
.base-width {
  width: 1400px;
  margin: 0 auto;
}

.top {
  height: 40px;
  background: #B4272B;
}

.head {

  margin: 0 auto;
  height: 350px;
  background: url('../../assets/footList/servicios-adicionales/banner-inspectionDetail.jpg') no-repeat 50%, 50%;
  background-size: auto;

  h2 {
    font-size: 36px;
    padding: 70px 0 50px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0 0 27px rgba(0, 0, 0, 0.4);
  }

  p {
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

    &:nth-child(2) {
      margin-bottom: 25px;
    }
  }

}

.text {
  padding: 80px 0;
  background-color: #fff;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
  text-align: center;

}

h3 {
  font-size: 30px;
  padding: 80px 0 50px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.vip {
  width: 100%;
  display: flex;
  padding-left: 296px;
  margin-bottom: 18px;

  .item {
    flex: 1;
    border-radius: 10px;
    background: linear-gradient(0deg, #A6A6A6, #D6D6D6);
    margin-left: 18px;
    text-align: center;
    color: #fff;
    padding: 22px 0;

    &:nth-child(2) {
      background: linear-gradient(0deg, #F16E2E, #FFAB82);
    }

    &:nth-child(3) {
      background: linear-gradient(0deg, #DA4E3E, #FF897C);
    }

    .grade {
      font-size: 18px;
      font-weight: bold;

    }
  }
}

.collectFee {
  margin-bottom: 80px;

  .row {
    display: flex;

    &:nth-child(odd) {
      background-color: #fff;
      border-radius: 10px;
    }

    .label {
      font-size: 18px;
      width: 296px !important;
      font-weight: bold;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;

    }

    .item {
      flex: 1;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 18px;
    }
  }
}

.serve {
  background-color: #fff;
  padding-bottom: 80px;
  margin-bottom: 30px;

  .sizedmaterial {
    background: #F6F6F6;
    border-radius: 10px 10px 0 0;
    padding: 27px 36px;
    display: flex;

    .label {
      width: 224px;

      h6 {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }

      .video {
        width: 224px;
        height: 126px;
        background: #435CB8;
        border-radius: 10px;
      }

      text-align: center;

    }

    .item {
      flex: 1;
      margin-left: 95px;

      .title {
        margin-bottom: 20px;
      }
    }
  }

  .imgs {
    padding: 36px;
    display: flex;

    .item {
      flex: 1;
      margin-right: 52px;
      height: 126px;
      background: #C2C2C2;
      border-radius: 10px;

      &:nth-last-child(1) {
        margin-right: 0;
      }

    }
  }
}

.foot {
  padding: 80px 0;

  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    line-height: 36px;
    text-align: center;
    margin-bottom: 80px;
  }

  .isVip {
    display: flex;
    width: 1000px;
    margin: 0 auto;

    .label {
      width: 300px;
      height: 150px;
      background: linear-gradient(0deg, #F16E2E, #FFAB82);
      border-radius: 12px 0 0 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;

      font-weight: bold;
      color: #FFFFFF;
    }

    .value {
      flex: 1;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 50px;
      line-height: 36px;
      border-radius: 0 12px 12px 0;
    }

    &:nth-last-child(1) {
      margin: 36px auto;

      .label {
        background: linear-gradient(0deg, #DA4E3E, #FF897C);
        border-radius: 0 12px 12px 0;
      }

      .value {
        border-radius: 12px 0 0 12px;
        text-align: right;
        padding: 0 133px;
      }
    }
  }
}
</style>
